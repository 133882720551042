import "bootstrap/dist/css/bootstrap.min.css";
import * as React from "react";
import { useState, useEffect } from "react";
import "../styles/style.scss";
import { useStaticQuery, graphql, Link } from "gatsby";
import { Helmet } from "react-helmet";

// components
import Navbar from "../components/common/Navbar";
import BenefitListItem from "../components/benefit_list_item";
import InnerHeroBanner from "../components/inner-hero-banner";
import Footer from "../components/common/Footer";

// images
//favicon
import favicon from "../images/favicon.ico";
import careerJoin from "../images/career-join.png";
import careerBannerImage from "../images/career-banner.jpg";

import { isEmpty } from "../utils/utils";

// markup
const IndexPage = () => {
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    window.onscroll = () => {
      setOffset(window.pageYOffset);
    };
  }, []);

  const data = useStaticQuery(graphql`
    {
      career: allFile(filter: { absolutePath: { regex: "/career.mdx/" } }) {
        nodes {
          childMdx {
            frontmatter {
              bannerTitle
              bannerSubTitle
              imageUrl {
                publicURL
              }
            }
            body
          }
        }
      }
      careerBenefits: allMdx(
        filter: { fileAbsolutePath: { regex: "content/career-benefit/" } }
        sort: { fields: frontmatter___order }
      ) {
        nodes {
          frontmatter {
            title
            icon {
              publicURL
            }
            order
          }
          body
        }
      }
      openings: allFile(
        filter: { sourceInstanceName: { eq: "openings" } }
        sort: { fields: childMdx___frontmatter___order }
      ) {
        nodes {
          childMdx {
            frontmatter {
              title
              subTitle
              keySkills
              experience
              detailUrl
            }
          }
        }
      }
    }
  `);

  const career = data?.career?.nodes?.[0]?.childMdx;

  return (
    <>
      <Helmet>
        <link rel="icon" href={favicon} />
        <link rel="preconnect" href="https://fonts.googleapis.com"></link>
        <link rel="preconnect" href="https://fonts.gstatic.com"></link>
        <link
          href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap"
          rel="stylesheet"
        ></link>
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto&display=swap"
          rel="stylesheet"
        ></link>
      </Helmet>
      <main>
        <title>NuFlights-Careers</title>
        <div className="inner-hero">
          <Navbar offset={offset} />
          <InnerHeroBanner
            bannerImage={careerBannerImage}
            heading={career?.frontmatter?.bannerTitle}
            subHeading={career?.frontmatter?.bannerSubTitle}
          />
        </div>

        {/*JOIN*/}
        <div className="inner-section">
          <div className="container">
            <div className="row">
              <div className="col-md-5">
                <p className="sub-text">Tranform yourself</p>
                <h2>Join the NuFlights Team</h2>
                <div className="content-wrapper">
                  <p>
                    Welcome to NuFlights, where innovation meets the sky. At
                    NuFlights, we're not just about flights; we're about
                    transforming the way people experience travel. Our team of
                    dedicated professionals is on a mission to redefine air
                    travel for the modern world.
                  </p>
                </div>
              </div>
              <div className="col-md-7 text-center">
                <img src={careerJoin} className="career-join-image" alt="" />
              </div>
            </div>
          </div>
        </div>
        {/*JOIN ENDS*/}

        {/*BENEFITS*/}
        <div className="benefits career-benefits-section">
          <div className="container">
            <p className="sub-text">Benefits </p>
            <h1>Why Nuflights?</h1>
            <div className="row">
              {data.careerBenefits.nodes.map((benefit, index) => {
                return <BenefitListItem benefit={benefit} index={index} />;
              })}
            </div>
          </div>
        </div>
        {/*BENEFITS ENDS*/}

        {/*OPENINGS*/}
        {!isEmpty(data?.openings?.nodes) && (
          <div className="inner-light-blue-bg">
            <div className="container">
              <p className="sub-text">Hiring</p>
              <div className="d-flex justify-content-between">
                <div>
                  <h1>Current Openings</h1>
                </div>
              </div>
              <div className="mt-3">
                <div className="row">
                  {data?.openings?.nodes?.map((node, index) => {
                    const openingFrontmatter = node?.childMdx?.frontmatter;
                    return (
                      <div className="col-md-4">
                        <div className="value-card opening">
                          <h3 className="mt-1">{openingFrontmatter?.title}</h3>
                          <div className="sub-heading-wrapper">
                            <h4 className="sub-heading">
                              {openingFrontmatter?.subTitle}
                            </h4>
                          </div>
                          <div className="key-skill-wrapper">
                            <p>
                              <span className="heading">Key Skills:</span>
                              {openingFrontmatter?.keySkills}
                            </p>
                          </div>
                          <p className="experience">
                            {openingFrontmatter?.experience}
                          </p>
                          <div className="text-left mb-2 mt-4">
                            <Link
                              to={openingFrontmatter?.detailUrl}
                              className="btn btn-primary mt-0"
                              target="_blank"
                            >
                              View
                            </Link>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        )}
        {/*OPENINGS ENDS*/}

        <Footer />
      </main>
    </>
  );
};

export default IndexPage;
